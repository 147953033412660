<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <!-- form -->
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(addAnnouncement)"
      >
        <b-form-row v-if="canUserAddAnnouncement">
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
                class="mb-2"
              >
                <b-form-input
                  id="title"
                  v-model="announcementData.activity_name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <validation-provider
          #default="validationContext"
          name="Content"
          rules="required"
        >
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          >
            <quill-editor
              id="blog-content"
              v-model="announcementData.activity_description"
              :options="snowOption"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-row>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="Start Date"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <flat-pickr
                  v-model="announcementData.start_time"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',minDate:'today'}"
                  @input="validationContext.validate(announcementData.start_time)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="End Date"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <flat-pickr
                  v-model="announcementData.end_time"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i',minDate:announcementData.start_time}"
                  @input="validationContext.validate(announcementData.end_time)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-group label="Media Type">
          <b-form-checkbox
            v-model="mediaType"
            name="check-button"
            switch
            value="Youtube Video"
            unchecked-value="Images"
          >
            <b>({{ mediaType }})</b>
          </b-form-checkbox>

        </b-form-group>

        <b-form-row v-if="mediaType === 'Youtube Video'">
          <b-col lg="6">
            <validation-provider
              #default="validationContext"
              name="youtube_link"
              rules="youtube-url"
            >
              <b-form-group label="Youtube Link">
                <b-form-input
                  v-model="announcementData.youtubeLink"
                  :state="getValidationState(validationContext)"
                  @change="setYoutubeLink"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row v-else>
          <b-col
            md="4"
            class="mb-2 border rounded p-2"
          >
            <validation-provider
              #default="validationContext"
              name="Images"
              rules=""
            >
              <b-form-group
                label="Featured Images"
                label-size="lg"
                label-for="images"
              >
                <b-form-file
                  id="images"
                  multiple
                  accept="image/*"
                  @input="handleImages"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col md="2">
            <validation-provider
              #default="validationContext"
              name="Order"
              rules="required"
            >
              <b-form-group
                label="Order"
                label-for="order"
              >
                <b-input
                  id="order"
                  v-model="announcementData.activity_order"
                  type="number"
                  :state="getValidationState(validationContext)"
                  min="0"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-form-group
            label="Comments"
            label-for="blog-edit-category"
            class="mb-2 mr-2"
          >
            <b-form-checkbox
              v-model="announcementData.enable_comments"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Likes"
            label-for="blog-edit-category"
            class="mb-2 mr-2"
          >
            <b-form-checkbox
              v-model="announcementData.enable_likes"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Share"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="announcementData.enable_share"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Status"
            label-for="blog-edit-category"
            class="mb-2 ml-2"
          >
            <b-form-checkbox
              v-model="announcementData.enable_status"
              :value="true"
              :unchecked-value="false"
            />
          </b-form-group>
        </b-form-row>

        <b-button
          variant="primary"
          class="mr-1"
          type="submit"
        >
          Add
        </b-button>
        <back />
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import formValidation from '@core/comp-functions/forms/form-validation'

import convertImagesToBase64 from '@/common/compositions/common/convertImagesToBase64'
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddAnnouncement',
  components: {
    quillEditor,
    flatPickr,
    Back,
  },
  data() {
    return {
      selectedFile: null,
      announcementData: {
        activity_name: '',
        activity_description: '',
        enable_status: true,
        enable_likes: '',
        enable_comments: '',
        enable_share: '',
        creator_id: 1,
        entity_id: this.$store.getters['mainEntity/getEntityId'],
        creator_type: 'ngo',
        activity_type: 'announcement',
        frequency: 0,
        activity_order: 0,
        activity_approval: 0,
        start_time: '',
        end_time: '',
        activate_time: 25, // to be removed,
        media_files: [],
      },
      mediaType: 'Youtube Video',
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    canUserAddAnnouncement: {
      get() {
        return this.$can('store', 'ActivityController')
      },
    },
  },
  methods: {
    addAnnouncement() {
      this.setImagesIfExist()
      this.setFormData(this.announcementData)
      this.$activities.post('internalops/announcement-activities', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.successfulOperationAlert('Added Successfully')
          this.$router.push('/announcements/list')
        }).catch(error => {
          this.showErrors(error.response.data.errors)
        })
    },
    setImagesIfExist() {
      if (this.base64Images.length !== 0) {
        this.announcementData.media_files = this.base64Images
      }
    },
    setYoutubeLink(link) {
      this.announcementData.media_files[0] = link
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const {
      handleImages,
      base64Images,
    } = convertImagesToBase64()

    const { formData, setFormData } = handleFormData()

    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      getValidationState,
      formData,
      setFormData,
      successfulOperationAlert,
      showErrors,
      handleImages,
      base64Images,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
